
import { mapState } from 'vuex';

export default {

  name: 'HomePage',
  head() {
    return this.$utils.head(this.story)
  },
  async asyncData({ $utils, params }) {
    return {
      story: await $utils.content('home')
    }
  },
  beforeMount() {
    if (!this.story) {
      this.$nuxt.$router.push('/404');
    }
  },
  computed: {
    ...mapState([
      'location',
      'user'
    ]),
    filteredComponents() {
      const selectedLocation = !!this.location
      const loggedIn = !!this.user
      let filteredSections;

      if (selectedLocation) {
        if (loggedIn) {
          filteredSections = this.story.content.sections.filter((b) => b.logged_in_state !== undefined ? b.logged_in_state : true);
        } else {
          filteredSections = this.story.content.sections.filter((b) => b.hot_state !== undefined ? b.hot_state : true);
        }
      } else {
        filteredSections = this.story.content.sections.filter((b) => b.cold_state !== undefined ? b.cold_state : true);
      }

      return filteredSections.map((b) => {
        // Make sure we render the components on the client that don't need to know if a user is logged in or if it's hot or cold state. This is because we only know if the user is hot or cold state on the client. If we dont do this, components that for example are cold state only will render on the server, show on the front for a brief moment and then disappear if front realizes that the user is hot state. If the components don't have any state property, don't render them on the client.
        const hasStates = 'logged_in_state' in b && 'hot_state' in b && 'cold_state' in b;
        const clientOnly = hasStates ? !(b.logged_in_state && b.hot_state && b.cold_state) : false;
        return { ...b, clientOnly };
      });
    }
  },
}
